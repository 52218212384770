.notfound__title {
    color: #111827;
    font-size: 2.25rem;
    line-height: 2.5rem;
    font-weight: 800;
    letter-spacing: -0.025em;
    text-align: center;

    @media (min-width: 640px) {
        font-size: 3rem;
        line-height: 1;
    }

    @media (min-width: 768px) {
        font-size: 3.75rem;
        line-height: 1;
    }

    @media (min-width: 1024px) {
        font-size: 3rem;
        line-height: 1;
    }

    @media (min-width: 1280px) {
        font-size: 3.75rem;
        line-height: 1;
    }

    &__small {
        display: block;
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 600;
        text-transform: uppercase;
        color: $primarycolor;
    }
}

.notfound__search {
    .search-form {
        margin: 0 auto 30px auto;
    }
}

.notfound__content {
    margin-top: 0.75rem;
    margin-bottom: 1.5rem;
    color: #6b7280;
    font-size: 1.125rem;
    line-height: 1.75rem;
    text-align: center;

    @media (min-width: 640px) {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }

    @media (min-width: 768px) {
        margin-top: 1.25rem;
    }
}

.notfound__button {
    display: flex;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    align-items: baseline;
    justify-content: center;
    color: $primarycolor;

    svg {
        display: inline;
        margin-top: 0.25rem;
        margin-right: 0.5rem;
        width: 0.75rem;
        height: 0.75rem;
        fill: $primarycolor;
    }
}
