.team__function {
    font-size: 19px;
    font-weight: 600;
    margin: -20px 20px 20px 0;
    color: $primarycolor;
}

.team__details {
    margin: 0 0 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    a {
        color: $body-font-color;
        margin: 0 32px 8px 0;
        display: flex;
        align-items: center;

        svg {
            margin: 0 10px 0  0;
            width: 18px;
            height: 18px;
            color: $primarycolor;
            fill: $primarycolor;
        }
    }
}

.team__singleimg {
    max-width: 444px;

    img {
        border-radius: $border-radius;
    }
}

@media screen and (max-width: 1199px) {

}

@media screen and (max-width: 991px) {

}

@media screen and (max-width: 767px) {

}

@media screen and (max-width: 575px) {

}
