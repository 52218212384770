.single__sidebar {
    > div {
        &:not(:last-child) {
            margin-bottom: 50px;
        }
    }
}

.sidebar__quicklinks,
.sidebar__contact,
.sidebar__nieuws {
    padding: 57px 73px;
    color: $white;
    background: #054b96 url('../../svg/background-toothwheel.svg') no-repeat;
    background-size: cover;
}

.sidebar__list {
    margin: 0;
    padding: 0;
    list-style: none;

    a {
        position: relative;
        color: $white;
        font-size: 22px;
        display: flex;
        align-items: flex-start;
        margin-bottom: 12px;
        padding-left: 32px;
        font-family: 'Conv_GraphikRegular';

        svg {
            width: 27px;
            height: 27px;
            margin: 3px 11px 0 0;
        }

        &::before {
            content: "";
            position: absolute;
            top: 3px;
            left: -5px;
            width: 27px;
            height: 27px;
            background: url('../../svg/angle-right-regular-white.svg') no-repeat;
            background-size: 27px 27px;
            transition: all 0.3s;
        }

        &:hover {
            &::before {
                left: -2px;
            }
        }
    }

    li {
        &:last-child {
            a {
                margin-bottom: 0;
            }
        }
    }
}

.sidebar__contact {
    .btn {
        display: block;
        margin-right: 0;
        text-align: left;

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}

.sidebar__title {
    display: block;
    font-size: 28px;
    color: $white;
    margin-bottom: 18px;
    line-height: 1.3;
    font-family: 'Conv_GraphikBold';
}

.sidebar__contact__text {
    display: block;
    margin-bottom: 20px;
}

.sidebar__nieuws__item {
    position: relative;
    display: block;
    padding: 7px 18px;
    border-radius: $border-radius;
    margin-right: 0;
    color: $body-font-color;
    text-align: left;
    background-color: #e4e4e4;

    &:not(:last-child) {
        margin-bottom: 10px;
    }

    &:hover {
        color: $body-font-color;
        background-color: darken(#e4e4e4, 3%);

        .sidebar__nieuws__arrow {
            right: 13px;
        }
    }
}

.sidebar__nieuws__title {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 18px;
}

.sidebar__nieuws__arrow {
    position: absolute;
    top: 50%;
    margin-top: -2px;
    right: 15px;
    transform: translateY(-50%);
    transition: all 0.3s ease;

    svg {
        width: 8px;
        height: 18px;
        color: $body-font-color;
        fill: $body-font-color;
    }
}

.sidebar__team {
    font-size: 19px;
    line-height: normal;

    a {
        color: $white;
        text-decoration: underline;
    }
}

.sidebar__team__title {
    font-family: 'Conv_GraphikBold';
    color: $primarycolor;
    font-size: 24px;
    margin-bottom: 10px;
}

.sidebar__team__desc {
    font-size: 17px;
}

.sidebar__team__name {
    font-family: 'Conv_GraphikBold';
    font-size: 19px;
    margin: 0 0 20px 0;
}

.sidebar__team__image {
    margin-right: 40px;

    img {
        max-width: 144px;
        min-width: 144px;
    }
}

.sidebar__team__info {
    color: #262626;
    display: flex;
    margin-top: 25px;
    align-items: center;

    a {
        color: #262626;
        margin: 12px 0 0 0;
        font-size: 17px;
        text-decoration: none;
        display: flex;
        align-items: flex-start;

        svg {
            width: 18px;
            height: 18px;
            margin-right: 11px;
            color: $primarycolor;
            margin-top: -1px;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}

@media screen and (max-width: 1599px) {
    .sidebar__quicklinks,
    .sidebar__contact,
    .sidebar__nieuws {
        padding: 35px 50px;
    }

    .sidebar__title {
        font-size: 24px;
        margin-bottom: 15px;
    }

    .sidebar__list {
        a {
            font-size: 18px;
            margin-bottom: 8px;
            padding-left: 27px;

            &::before {
                top: 4px;
                width: 23px;
                height: 23px;
                background-size: 23px 23px;
            }
        }
    }

    .sidebar__team__name {
        font-size: 17px;
    }

    .sidebar__team__image {
        img {
            min-width: 130px;
            max-width: 130px;
        }
    }
}

@media screen and (max-width: 1199px) {
    .sidebar__team__name {
        font-size: 16px;
    }

    .sidebar__team__info a {
        font-size: 16px;
    }
}

@media screen and (max-width: 991px) {
    .single__sidebar {
        margin-top: 40px;
        max-width: 480px;
    }

    .sidebar__team__name {
        font-size: 15px;
    }
}

@media screen and (max-width: 767px) {
    .single__sidebar > div:not(:last-child) {
        margin-bottom: 30px;
    }

    .sidebar__quicklinks,
    .sidebar__contact,
    .sidebar__nieuws {
        padding: 25px 40px;
        margin-bottom: 30px;
    }

    .sidebar__title {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .sidebar__list {
        a {
            font-size: 15px;
            margin-bottom: 10px;
            padding-left: 18px;

            &::before {
                top: 2px;
                width: 18px;
                height: 18px;
                background-size: 18px 18px;
            }
        }
    }

    .sidebar__team__image {
        margin-right: 20px;
        width: 75px;

        img {
            min-width: 75px;
            max-width: 75px;
        }
    }

    .sidebar__team__name {
        font-size: 15px;
        margin: 0 0 12px 0;
    }

    .sidebar__team__title {
        font-size: 16px;
    }

    .sidebar__team__desc {
        font-size: 15px;
    }

    .sidebar__team__info a {
        font-size: 15px;
        margin: 8px 0 0 0;
    }

    .sidebar__team__info a svg {
        width: 13px;
        height: 13px;
        margin-top: 0;
    }
}

@media screen and (max-width: 575px) {

}
