
// Page
.row--image {
    margin: 96px 0;

    .img-fluid {
        width: 100%;
    }

    @media screen and (max-width: 1024px) {
        margin: 50px 0;
    }
}

.row--page {
    margin-bottom: 96px;

    @media screen and (max-width: 1024px) {
        margin-bottom: 50px;
    }

    .col {
        > p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.row--gallery {
    .col {
        &:nth-child(n+5) {
            margin-top: 30px;
        }
    }

    .img-fluid {
        overflow: hidden;
    }
}

.row--columns {
    a {
        margin-top: 38px;
    }
}

.page__slider {
    position: relative;

    .slick-dots {
        bottom: -43px;
    }

    .page__slider__item {
        a {
            &:focus {
                outline: none;
            }
        }

        &:focus {
            outline: none;
        }
    }

    &.slick-dotted {
        &.slick-slider {
            margin-bottom: 40px;
        }
    }

    .slick-arrow {
        position: absolute;
        font-size: 36px;
        text-align: center;
        top: 50%;
        margin-top: -25px;
        cursor: pointer;
        width: 50px;
        height: 50px;

        &.fa-angle-left {
            left: -40px;
        }

        &.fa-angle-right {
            right: -40px;
        }
    }
}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }
}

@media screen and (max-width: 1275px) {
    .row--slider {
        padding: 0 40px;
    }
}

@media screen and (max-width: 991px) {
    .row--gallery {
        .col {
            &:nth-child(n+4) {
                margin-top: 30px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .row--columns {
        > .col {
            margin-bottom: 20px;
        }
    }

    .row--gallery {
        .col {
            &:nth-child(n+3) {
                margin-top: 30px;
            }
        }
    }
}

@media screen and (max-width: 380px) {
    .row--gallery {
        > .col {
            flex: 0 0 100%;
            max-width: 100%;
            margin-top: 30px;

            &:first-child {
                margin-top: 0;
            }
        }
    }
}
