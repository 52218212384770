.banner {
    position: relative;
    line-height: 0;

    &.size--small {
        .banner__slide {
            picture {
                display: block;
                height: 697px;
                max-height: 90vh;

                @media screen and (max-width: 1199px) {
                    height: 484px;
                }
            }
        }

        .banner__content {
            padding-top: 40px;
        }

        video {
            width: 100%;
            height: 697px;
            max-height: 90vh;
            object-fit: cover;

            @media screen and (max-width: 1199px) {
                height: 484px;
            }
        }
    }

    &.size--groot {
        .banner__slide {
            picture {
                display: block;
                height: 878px;
                max-height: 90vh;

                @media screen and (max-width: 1199px) {
                    height: 484px;
                }
            }
        }

        video {
            width: 100%;
            height: 878px;
            max-height: 90vh;
            object-fit: cover;

            @media screen and (max-width: 1199px) {
                height: 484px;
            }
        }
    }

    iframe {
        display: table;
    }

    video {
        width: 100%;
        height: 878px;
        object-fit: cover;

        @media screen and (max-width: 1199px) {
            height: 484px;
        }
    }

    &.banner__sub__video {
        video {
            position: relative;
            right: auto;
            left: auto;
            bottom: auto;
            width: 100%;
        }
    }

    img {
        width: 100%;
    }

    .slick-slide {
        img {
            border-radius: 0;
        }
    }

    &::before {
        display: inline-block;
        content: "";
        background: rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        z-index: 1;
    }
}

.home {
    .banner {
        overflow: hidden;
    }
}

.banner--sub {
    &::before {
        bottom: auto;
        height: 150px;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0) 100%);
    }
}

.banner__content__outer {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1199px) {
        align-items: end;
        padding-bottom: 34px;
    }
}

.slick-dotted {
    &.slick-slider {
        margin-bottom: 0;
    }
}

.slick-dots {
    bottom: 50px;

    li {
        width: 13px;
        height: 13px;
        margin: 0 3px;

        button {
            width: 13px;
            height: 13px;
            padding: 0;

            &::before {
                content: "";
                width: 13px;
                height: 13px;
                line-height: 20px;
                text-align: center;
                border: 1px solid $primarycolor;
                border-radius: 50%;
                opacity: 1;
            }
        }

        &.slick-active {
            button {
                &::before {
                    opacity: 1;
                    background-color: $primarycolor;
                }
            }
        }
    }
}

.banner__slide {
    picture {
        display: block;
        height: 794px;

        @media screen and (max-width: 1199px) {
            height: 484px;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}

.banner__slider {
    line-height: 0;
}

.banner__content {
    position: relative;
    width: 50%;
    color: white;
    z-index: 2;

    @media screen and (max-width: 767px) {
        text-align: center;
    }

    @media screen and (max-width: 1400px) {
        width: 100%;
    }

    h1 {
        margin-bottom: 0;
        font-family: "Conv_GraphikBlack";
        font-weight: 400;
        line-height: 1;
        color: #fff;
        font-size: 60px;

        @media screen and (max-width: 1199px) {
            font-size: 30px;
        }
    }
}

.banner__title {
    display: block;
    margin-bottom: 20px;
}

.banner__description {
    display: block;
    font-size: 24px;
    line-height: 32px;
    font-family: 'Conv_GraphikRegular';

    @media screen and (max-width: 1199px) {
        font-size: 16px;
        line-height: 24px;
    }
}

.banner__buttons {
    margin-top: 34px;
    margin-bottom: 40px;
    display: flex;
    column-gap: 20px;
    row-gap: 8px;

    @media screen and (max-width: 1024px) {
        [data-src="#offerte_aanvragen__form"] {
            display: none;
        }
    }

    @media screen and (max-width: 767px) {
        flex-direction: column;
        justify-content: center;
    }

    .btn {
        font-size: 20px;
        padding-top: 16px;
        padding-bottom: 16px;

        @media screen and (max-width: 1199px) {
            font-size: 16px;
            padding-top: 14px;
            padding-bottom: 14px;
        }

        svg {
            width: 12px;
            min-width: 12px;
            margin-left: 16px;
        }
    }
}

.banner__usps {
    display: flex;
    column-gap: 24px;

    svg {
        color: white;
        width: 12px;
        min-width: 12px;
    }

    &__usp {
        display: flex !important;
        align-items: center;
        column-gap: 17px;
        font-size: 14px;

        @media screen and (max-width: 1199px) {
            text-align: center;
        }

        @media screen and (max-width: 767px) {
            justify-content: center;
        }

        span {
            line-height: normal;
        }
    }
}
