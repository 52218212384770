.page-template-locaties {
    .block {
        background: #f4f6fb;
        padding: 40px 46px;
        height: 100%;
        display: block;

        &:hover {
            .block__readmore {
                background: $secondarycolor;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
        }

        &__title {
            color: #191919;
            font-size: 24px;
            line-height: 32px;
            font-family: Conv_GraphikBold;
            margin-bottom: 16px;
        }

        &__summary {
            color: #374151;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 20px;
        }

        &__readmore {
            background: $primarycolor;
            width: 37px;
            height: 37px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            transition: 0.2s ease-in-out background;

            svg {
                width: 13px;
                min-width: 13px;
            }
        }
    }

    .archive__list {
        .row {
            row-gap: 40px;
        }
    }
}

.single__main .locaties__overview {
    color: $primarycolor;
    border-bottom: 1px solid $primarycolor;
    font-size: 14px;
    line-height: 20px;
    font-family: Conv_GraphikSemibold;
    text-decoration: none;
    padding-bottom: 6px;

    &:hover {
        border-color: transparent;
    }

    svg {
        width: 13px;
        min-width: 13px;
        fill: $primarycolor;
        margin-right: 9px;
    }
}

@media screen and (max-width: 1199px) {

}

@media screen and (max-width: 991px) {
    .single__main--locaties {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 767px) {
    .single__main--locaties {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 575px) {

}
