.btn {
    position: relative;
    padding: 14px 22px;
    border: solid 1px;
    border-radius: 3px;
    font-family: "Conv_GraphikMedium";
    color: $white;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    justify-content: center;

    &:hover,
    &:active,
    &:focus {
        box-shadow: none !important;
        color: $white;
    }

    &:last-child {
        margin-right: 0;
    }
}

.btn--primary {
    background-color: $primarycolor;
    border-color: $primarycolor !important;

    &:hover,
    &:active,
    &:focus {
        background-color: lighten($primarycolor, 5) !important;
        border-color: lighten($primarycolor, 5) !important;
    }
}

.btn--secondary {
    background-color: $secondarycolor;

    &:hover,
    &:active,
    &:focus {
        background-color: lighten($secondarycolor, 5) !important;
        border-color: lighten($secondarycolor, 5) !important;
    }
}

.btn--hollow {
    background-color: transparent;
    border: solid 1px rgba(255, 255, 255, 0.5) !important;
    color: white;

    &:hover,
    &:active,
    &:focus {
        background-color: $primarycolor;
        border-color: $primarycolor !important;
    }
}

.btn--hollow--primary {
    background-color: transparent;
    border: solid 1px $primarycolor !important;
    color: $primarycolor;

    &:hover,
    &:active,
    &:focus {
        background-color: $primarycolor;
        color: white;
    }
}

.btn--next {
    background-color: $primarycolor;
    padding-right: 38px;

    &::after {
        content: '';
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -9px;
        width: 7px;
        height: 18px;
        background-image: url('../../images/angle-right-regular-white.svg');
        background-size: 7px 18px;
    }

    &:hover,
    &:active,
    &:focus {
        background-color: lighten($primarycolor, 5) !important;
    }
}

.btn--prev {
    background-color: $primarycolor;
    padding-left: 83px;
    padding-right: 23px;

    &:hover,
    &:active,
    &:focus {
        background-color: lighten($primarycolor, 5) !important;
    }

    &::after {
        transform: rotate(180deg);
        left: 0;
    }
}

.btn--prev1 {
    background-color: $primarycolor;
    padding-left: 83px;
    padding-right: 23px;

    &:hover,
    &:active,
    &:focus {
        background-color: lighten($primarycolor, 5) !important;
    }

    &::after {
        transform: rotate(180deg);
        left: 0;
    }
}

.btn-danger {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;

    svg {
        width: 15px;
        height: 15px;
        margin-left: 10px;
    }
}

//@media screen and (max-width: 1599px) {
//    .btn,
//    .btn--prev1 {
//        font-size: 15px;
//    }
//
//    .btn--prev1 {
//        padding-left: 80px;
//        padding-right: 20px;
//    }
//}
//
//@media screen and (max-width: 1199px) {
//    .btn,
//    .btn--prev1 {
//        padding: 11px 60px 11px 15px;
//        font-size: 14px;
//
//        &::after {
//            width: 44px;
//            background-size: 14px 14px;
//        }
//    }
//
//    .btn--prev1 {
//        padding-left: 60px;
//        padding-right: 15px;
//    }
//}
