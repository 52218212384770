.page-template-contact {
    .main__inner {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.marker__inner {
    display: block;
    padding: 8px 10px 8px 10px;
}

.marker__title {
    display: block;
    margin: 0 0 8px 0;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    color: $primarycolor;
}

.marker__item {
    display: block;
    width: 100%;
    margin-bottom: 5px;
    font-size: 15px;
    text-align: center;

    &.btn {
        margin: 15px 0 0 0;
    }
}

.contact__main__inner {
    padding: 96px 0;
}

.contact__form__inner {
    padding: 96px 0 96px 28px;

    .gfield {
        textarea {
            max-height: 180px;
        }
    }
}

.contact__form__title {
    display: block;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 700;
}

.contact__form__text {
    display: block;
    margin-bottom: 25px;
}

h3.contact__company {
    margin-top: 0;
    margin-bottom: 2px;
    font-size: 16px;
    color: $primarycolor;
    font-family: Conv_GraphikBold;
}

.contact__address {
    span {
        display: block;
    }
}

.contact__accessibility {
    margin: 24px 0;
    padding: 0;
    list-style: none;
    line-height: 1.6;
}

.contact__icon {
    margin-right: 12px;

    svg {
        width: 16px;
        height: 16px;
        color: #374151;
        fill: #374151;
    }
}

.contact__line {
    display: flex;
    line-height: 31px;
}

.contact__text {
    transition: all 0.3s;
    color: $body-font-color;
}

.contact__details {
    margin-bottom: 20px;
}

.contact__link {
    &:first-child {
        margin-bottom: 12px;
    }

    &:hover {
        .contact__text {
            color: $secondarycolor;
        }
    }
}

.contact__line__col {
    width: 75px;
    font-weight: bold;
}

.contact__main {
    background: #f9fafb;

    &::before {
        content: "";
        position: absolute;
        right: 100%;
        width: 100vw;
        height: 100%;
        background: #f9fafb;
    }
}

.contact__image {
    display: block;
    width: 100%;
    max-width: 413px;
    margin-top: 55px;
}

.contact__route__link {
    font-family: 'Conv_GraphikMedium';
    color: #099a6e;

    &:hover,
    &:focus,
    &:active {
        color: lighten($secondarycolor, 5);
    }
}

@media screen and (max-width: 1199px) {
    .contact__main__inner {
        padding: 40px 50px;
    }

    .contact__form__inner {
        padding: 40px 0 40px 28px;
    }
}

@media screen and (max-width: 991px) {
    .contact__form {
        order: 2;
    }

    .contact__main__inner {
        padding: 50px 0;
    }

    .contact__form__inner {
        width: 100%;
        padding: 50px 0;
    }

    .contact__main {
        order: 1;
    }
}

@media screen and (max-width: 767px) {
    .contact__form__inner {
        padding: 40px 0;
    }
}

@media screen and (max-width: 575px) {

}
