/* Pagination numbered */
.pagination__numbers {
    border-top: 1px solid #eee;
    width: 100%;
    padding-top: 50px;
    display: flex;
    justify-content: center;

    .page-numbers {
        width: 30px;
        height: 30px;
        border-radius: 4px;
        background: $primarycolor;
        display: flex;
        float: left;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 15px;
        margin: 0 3px;
        text-decoration: none;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover,
        &:focus {
            background-color: darken($primarycolor, 5);
            text-decoration: none;
        }

        &.current {
            background-color: darken($primarycolor, 5);
        }
    }
}

.single__title {
    text-align: center;
}

.single__overview {
    color: $primarycolor;
    border-bottom: 1px solid $primarycolor;
    font-size: 14px;
    line-height: 20px;
    font-family: Conv_GraphikSemibold;
    text-decoration: none;
    padding-bottom: 6px;
    transition: 0.3s ease-in-out all;

    &:hover {
        border-color: transparent;
    }

    svg {
        width: 13px;
        min-width: 13px;
        fill: $primarycolor;
        margin-right: 9px;
    }
}

.single__date {
    display: block;
    margin-top: -25px;
    margin-bottom: 25px;
    color: #374151;
    font-size: 14px;

    svg {
        position: relative;
        top: -2px;
        width: 14px;
        height: 16px;
        margin-right: 8px;
        color: #505050;
        fill: #505050;
    }
}

.single__image {
    border-radius: 3px;
    overflow: hidden;
    margin-bottom: 32px;

    img {
        max-height: 385px;
        object-fit: cover;
        object-position: center;
    }
}

.single__text {
    margin-bottom: 28px;

    ul {
        padding-left: 20px;
    }
}

.breadcrumb__main {
    position: absolute;
    z-index: 2;
    bottom: 30px;
    width: 100%;
}

.breadcrumb__list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    row-gap: 14px;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    padding: 0;
    margin: 0;
    color: white;

    .fa-home {
        width: 16px;
        margin-top: -4px;
    }

    .fa-angle-right {
        width: 7px;
        height: 17px;
        margin: -2px 15px 0 15px;
        color: white;
    }

    a {
        color: white;

        &:hover {
            color: #f5f5f5;
        }
    }

    .breadcrumb__list__last {
        color: white;
    }
}

@media screen and (max-width: 767px) {
    .breadcrumb__list {
        font-size: 14px;

        .fa-angle-right {
            margin: -2px 13px 0 13px;
        }

        .fa-home {
            width: 16px;
            margin-top: -4px;
        }
    }

    .single__date {
        margin-top: -15px;
        margin-bottom: 15px;
        font-size: 15px;
    }
}
