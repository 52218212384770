.methods {
    padding-top: 96px;
    max-width: 1536px;
    overflow-x: hidden;
    margin: 0 auto;

    @media screen and (max-width: 1024px) {
        padding-top: 50px;
    }

    &__content {
        text-align: center;
        margin-bottom: 30px;
        font-size: 18px;
        line-height: 28px;

        &__title {
            font-family: Conv_GraphikBlack;
            font-size: 48px;
            line-height: 48px;
            margin-bottom: 18px;

            @media screen and (max-width: 1024px) {
                font-size: 30px;
                line-height: 36px;
            }
        }
    }

    &__list {
        visibility: hidden;

        .slick-slide:last-child .methods__list__item__bullet__line {
            background: white;
            height: 4px;
        }

        &__item {
            text-align: center;
            padding-left: 30px;
            padding-right: 30px;

            &__bullet {
                display: block;
                position: relative;
                width: 25px;
                height: 25px;
                margin: 0 auto 25px;

                &__circle {
                    display: block;
                    width: 100%;
                    height: 100%;
                    border: solid 2px $primarycolor;
                    background: white;
                    border-radius: 100%;
                }

                &__line {
                    display: block;
                    width: 100vw;
                    height: 2px;
                    background-color: $primarycolor;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: -10;
                }
            }

            &__title {
                font-size: 20px;
                line-height: 28px;
                margin-bottom: 11px;
                font-family: Conv_GraphikBold;
            }

            &__content {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }

    &__navigation {
        display: flex;
        justify-content: center;
        column-gap: 17px;

        &--next,
        &--prev {
            display: block;
            background: #f4f6fb;
            border: none;
            border-radius: 3px;
            width: 40px;
            height: 40px;

            &:focus {
                outline: none;
            }

            svg {
                width: 6px;
                min-width: 6px;
            }
        }
    }
}
