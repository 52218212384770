.download {
    max-width: 100%;
    position: relative;
    margin: 55px 0;

    svg {
        fill: $primarycolor;
        min-width: 15px;
        width: 15px;
        margin-right: 18px;
        margin-top: 4px;
    }
}

.download .download__item {
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 19px;
    color: $body-font-color;
    color: #505050;
    background: #f4f6fb;
    border-radius: 3px;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
        color: $body-font-color;

        .download__btn {
            color: $white;
            background-color: lighten($secondarycolor, 5);

            &::after {
                background-color: $secondarycolor;
            }
        }
    }
}

.download__btn {
    font-family: 'Conv_GraphikMedium';
    font-size: 16px;
    color: white;
    background: $primarycolor;
    height: 42px;
    display: flex;
    align-items: center;
    padding: 0 18px;
    position: relative;
    margin-left: 10px;
    border-radius: 3px;
}

.download__preview svg {
    width: 26px;
    min-width: 26px;
}

.download__item__title {
    display: flex;
    align-items: flex-start;
    flex-grow: 1;
    margin-right: 15px;
}

@media screen and (max-width: 1199px) {
    .download {
        margin: 35px 0;
    }

    .download__btn {
        font-size: 14px;

        &::after {
            background-size: 14px;
        }
    }
}

@media screen and (max-width: 767px) {
    .download__item__title {
        margin-left: 8px;
        text-align: left;
        word-break: break-word;
    }

    .download {
        svg {
            margin-top: 2px;
        }
    }

    .download__btn {
        min-width: 35px;
        width: 35px;
        height: 35px;
        overflow: hidden;
        padding: 0;

        &::after {
            width: 35px;
            height: 35px;
        }
    }
}
