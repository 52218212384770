.search-form {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 500px;

    label {
        flex: 1;
        margin-bottom: 0;
    }

    .search-submit {
        @extend .btn--primary;

        border: none;
        padding: 9px 22px 10px;
        font-size: 15px;
        text-transform: unset;
        outline: none;
        height: 42px;
        color: #fff;

        &:hover,
        &:active,
        &:focus {
            box-shadow: none;
        }
    }
}

.search-field {
    margin: 0;
    padding: 8px 15px 8px 15px;
    width: 100%;
    border: 1px solid #eee;
    border-right: 0;
    border-radius: 0;
    outline: none;
    resize: none;
    height: 42px;
}

.search__content {
    position: relative;
}

.search__word {
    font-size: 18px;
    color: $primarycolor;
    display: block;

    b {
        font-weight: 700;
    }
}

.search__word__amount {
    display: block;
    margin: 30px 0 30px;
}

.search__amount {
    display: block;
    font-size: 14px;
    color: #939393;
}

.search__result__row {
    position: relative;
    display: block;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #ececec;

    &:hover {
        text-decoration: none;

        .search__result__title {
            text-decoration: underline;
        }
    }
}

.search__result__title {
    color: $primarycolor;
    margin-bottom: 3px;
    display: block;
    font-size: 20px;
    font-family: 'Conv_GraphikSemibold';
}

.search__result__path {
    display: block;
    margin-bottom: 10px;
    font-size: 15px;
    color: #4e4e4e;
    opacity: 0.5;

    svg {
        position: relative;
        top: -1px;
        height: 14px;
        width: 5px;
        margin: 0 10px;
        fill: $secondarycolor;
        opacity: 0.9;
    }
}

.search__result__text {
    display: block;
    color: $body-font-color;
}
