.fancybox-caption {
    background: none;
    font-family: 'Conv_GraphikBold';
    font-size: 29px;
    color: $white;
}

.fancybox-custom-layout {
    .fancybox-thumbs {
        top: auto;
        bottom: 0;
        left: 0;
        right: 0;
        height: 95px;
        padding: 10px 10px 5px 10px;
        box-sizing: border-box;
        background: rgba(0, 0, 0, 0.3);
        width: 100%;
        margin: 0 auto;
    }

    &.fancybox-show-thumbs .fancybox-inner {
        right: 0;
        bottom: 95px;
        width: 100%;
    }

    .fancybox-caption--separate {
        margin-bottom: 110px;
    }
}

.fancybox-navigation .fancybox-button,
.fancybox-infobar {
    opacity: 1;
    visibility: visible;
}

.fancybox-button {
    background: none;
}

.fancybox-navigation .fancybox-button {
    width: auto;

    svg {
        width: 60px;
        height: 60px;

        path {
            fill: $white;
        }
    }

    &:disabled {
        opacity: 0;
    }
}

.fancybox-close-small {
    padding: 10px;
    right: 0;
    top: 0;
    opacity: 1;
    color: #fff;
    width: 80px;
    height: 80px;
    transition: all 0.3s;
    background-color: $secondarycolor;

    svg {
        width: 60px;
        height: 60px;
    }

    &:hover {
        color: #fff;
    }
}

.fancybox-slide--html .fancybox-close-small {
    color: rgba(#000, 0.7);
    width: 80px;
    height: 80px;
    padding: 20px;
    right: 0;
    top: 0;
    background-color: transparent;

    svg {
        width: 40px;
        height: 40px;
    }

    &:hover {
        color: #000;
    }
}

.fancybox-infobar {
    padding: 0;
    mix-blend-mode: initial;
}

.fancybox-show-caption .fancybox-navigation {
    display: inherit;
}

.hidden__form {
    max-height: 90vh;
    padding: 25px 40px;
}

@media screen and (max-width: 1599px) {
    .fancybox-caption {
        font-size: 25px;
    }
}

@media screen and (max-width: 1199px) {
    .fancybox-infobar {
        &::after {
            width: 220px;
            height: 76px;
            padding: 0;
            background-size: 200px 56px;
        }
    }

    .fancybox-caption {
        font-size: 22px;
    }
}

@media screen and (max-width: 767px) {
    .fancybox-caption {
        font-size: 20px;
    }

    .fancybox-slide--html .fancybox-close-small {
        width: 40px;
        height: 40px;
        padding: 5px;

        svg {
            width: 30px;
            height: 30px;
        }
    }
}
