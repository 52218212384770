.single__img__nieuws {
    position: relative;
    display: block;
    width: 100%;
    max-width: 800px;
    margin-bottom: 25px;
    overflow: hidden;

    h1 {
        position: absolute;
        bottom: 30px;
        left: 0;
        color: white;
        padding: 0 30px;
        width: 100%;
        text-shadow: 0 0 12px rgba(0, 0, 0, 0.36);
        margin-bottom: 0;
    }
}

.block--news {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 50px;

    &:hover .block__img {
        img {
            transform: scale(1.05);
        }
    }

    .block__img {
        border-radius: 3px;
        overflow: hidden;

        img {
            transition: transform 0.3s ease-in-out;
        }
    }

    .block__title {
        font-size: 30px;
        line-height: 36px;
        font-family: Conv_GraphikBlack;
        color: white;

        @media screen and (max-width: 1024px) {
            font-size: 20px;
            line-height: 28px;
        }
    }

    .block__date {
        font-size: 14px;
        line-height: 20px;
    }

    .block__content {
        padding-top: 34px;
        color: white;
        font-size: 18px;
        line-height: 28px;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }

    .block__readmore {
        max-width: max-content;
        margin-top: 14px;
    }
}

@media screen and (max-width: 991px) {

}

@media screen and (max-width: 767px) {
    .single__img__nieuws {
        h1 {
            bottom: 20px;
        }
    }
}

@media screen and (max-width: 575px) {

}
