.hidden__form {
    display: none;
    max-width: 800px;
    margin: 0 20px;
    padding: 80px 100px;

    .gfield textarea {
        height: 150px;
    }
}

.gform_submission_error {
    font-size: 14px !important;
    color: #ef5350 !important;
}

.gform_validation_container {
    height: 0;
    margin: 0;
    padding: 0;
    visibility: hidden;
    opacity: 0;
    max-height: 0;
}

.gfield {
    margin-bottom: 15px;

    input,
    select,
    textarea {
        width: 100%;
        height: 42px;
        padding: 10px 15px;
        font-size: 15px;
        border: 1px solid #d5d5d5;
        border-radius: 0;
        background-color: #fff;
        box-shadow: 0 0 0 transparent;

        &:focus {
            border-radius: 0;
            outline: none;
        }

        &::placeholder {
            color: #bebebe;
        }
    }

    textarea {
        height: auto;
    }

    .ginput_container_fileupload {
        .validation_message {
            display: none;
        }
    }

    &.recaptcha {
        height: 0;
        margin: 0;
        padding: 0;
        opacity: 0;
    }
}

h3.gform_title {
    margin-top: 0;
    color: #262626;
}

.gform_description {
    display: block;
    margin-bottom: 23px;
}

.gfield_label {
    margin-bottom: 10px;
    font-family: Conv_GraphikMedium;
    font-size: 14px;
    color: #374151;
}

.gfield_checkbox {
    margin: 0;
    padding: 0;
    list-style: none;

    > li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;

        label {
            position: relative;
            display: flex;
            align-items: flex-start;
            margin: 0;
            cursor: pointer;
            font-weight: 400;

            &::before,
            &::after {
                display: inline-block;
                content: " ";
            }

            &::before {
                width: 15px;
                min-width: 15px;
                height: 15px;
                min-height: 15px;
                margin-top: 5px;
                margin-right: 15px;
                border: 1px solid #d5d5d5;
                border-radius: 3px;
            }
        }

        input {
            position: absolute;
            width: 0;
            visibility: hidden;
            opacity: 0;

            &:checked + label {
                &::after {
                    position: absolute;
                    top: 8px;
                    left: 2px;
                    width: 12px;
                    height: 9px;
                    content: "";
                    background-image: url("../../images/checkbox-solid.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 12px 9px;
                }
            }
        }
    }
}

.clear-multi {
    display: flex;
    margin: 0 -10px;

    > * {
        padding: 0 10px;
    }
}

.ginput_container_list {
    table {
        width: 100%;
    }

    tbody {
        display: flex;
        flex-direction: column;

        > tr {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;

            .gfield_list_cell {
                width: 100%;
            }

            .gfield_list_icons {
                display: flex;
                align-items: center;
                justify-content: center;

                > a {
                    margin: 0 3px;
                }
            }
        }
    }
}

.ginput_container_select {
    position: relative;

    &::after {
        position: absolute;
        top: 50%;
        right: 15px;
        width: 10px;
        height: 16px;
        content: "";
        transform: translateY(-50%);
        background-image: url("../../images/angle-down-regular.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 10px 16px;
    }

    .gfield_select {
        padding-top: 7px;
        appearance: none;

        &::-ms-expand {
            display: none; /* remove default arrow in IE 10 and 11 */
        }
    }
}

.gfield_time_ampm {
    min-width: 85px;
    font-size: 14px;
}

.ginput_container_name {
    display: flex;
    margin: 0 -15px;

    > span {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 15px;
    }

    label {
        display: block;
        order: 0;
    }
}

.ginput_container_post_image {
    input {
        padding: 4px;
    }
}

.ginput_container_fileupload {
    > input {
        padding: 4px;
    }
}

.ginput_container_address {
    > span {
        display: block;
        margin-bottom: 15px;

        > label {
            display: block;
            margin-top: 4px;
        }
    }
}

.gfield_contains_required {
    > .gfield_label {
        .gfield_required {
            color: #dc2626;
        }
    }
}

.ginput_container_consent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: -15px;

    a {
        text-decoration: underline;
        color: #138e9d;
    }

    > input {
        width: auto;
        height: 23px;
        margin-right: 15px;
    }

    > label {
        margin-bottom: 0;
    }
}

.gfield_radio {
    margin: 0;
    padding: 0;
    list-style: none;

    > li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;

        label {
            position: relative;
            display: flex;
            align-items: flex-start;
            margin: 0;
            cursor: pointer;
            font-weight: 400;

            &::before,
            &::after {
                display: inline-block;
                content: "";
            }

            &::before {
                width: 15px;
                min-width: 15px;
                height: 15px;
                min-height: 15px;
                margin-top: 5px;
                margin-right: 15px;
                border: 1px solid #d5d5d5;
                border-radius: 50px;
            }
        }

        input {
            position: absolute;
            width: 0;
            visibility: hidden;
            opacity: 0;

            &:checked + label {
                &::after {
                    position: absolute;
                    top: 8px;
                    left: 3px;
                    width: 9px;
                    height: 9px;
                    content: "";
                    border-radius: 50%;
                    background-color: $primarycolor;
                }
            }
        }
    }
}

.ginput_container_multiselect {
    select {
        height: auto;
    }
}

.gfield_required {
    margin-left: 5px;
}

.gform_fields {
    display: flex;
    flex-direction: column;
    margin: 0 -15px;
    padding: 0;
    list-style: none;

    > li {
        padding: 0 15px;

        &.gform_hidden {
            position: absolute !important;
            overflow: hidden !important;
            height: 0 !important;
            margin: 0 !important;
            padding: 0 !important;
        }

        &.full {
            flex: auto;
            width: 100%;
            max-width: 100%;
        }
    }
}

.validation_error {
    margin: 0 0 15px;
    font-size: 14px;
    font-weight: 500;
    color: #ef5350;
}

.validation_message {
    position: relative;
    display: flex;
    align-items: center;
    margin: 5px 0 0;
    font-size: 12px;
    font-weight: 500;
    color: #ef5350;

    &::after {
        position: relative;
        width: 14px;
        height: 12px;
        margin: 0 0 0 auto;
        content: '';
        background-image: url('../../images/exclamation-triangle-solid-red.svg');
        background-size: 14px 12px;
    }
}

.gform_button {
    margin: 5px 0 0;
}

.gform_ajax_spinner {
    position: relative;
    top: 6px;
    left: 10px;
    width: 30px;
    height: 30px;
}

#ui-datepicker-div {
    display: none;
    width: 300px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #fff;
    box-shadow: (0 3px 5px 0 rgba(50, 50, 50, 0.75));

    .ui-icon {
        cursor: pointer;
        font-size: 0;
        color: transparent;
    }

    .ui-icon::before {
        font-size: 18px;
    }

    .ui-datepicker-prev {
        display: flex;
        align-items: center;
        justify-content: center;
        float: left;
        width: 10%;
        cursor: pointer;

        .ui-icon::before {
            position: relative;
            float: left;
            width: 10px;
            height: 19px;
            content: '';
            background-image: url('../../images/angle-left-regular.svg');
            background-repeat: no-repeat;
            background-size: 9px;
        }

        &.ui-state-disabled {
            display: none;
        }
    }

    .ui-datepicker-next {
        display: flex;
        align-items: center;
        justify-content: center;
        float: right;
        width: 10%;
        cursor: pointer;

        .ui-icon::before {
            position: relative;
            float: right;
            width: 10px;
            height: 19px;
            content: '';
            background-image: url('../../images/angle-right-regular.svg');
            background-repeat: no-repeat;
            background-size: 9px;
        }

        &.ui-state-disabled {
            display: none;
        }
    }

    .ui-datepicker-header {
        display: block;
        float: left;
        width: 100%;
        margin-top: 12px;
    }

    .ui-datepicker-title {
        select {
            float: left;
            width: 70%;
        }

        .ui-datepicker-month {
            margin: 0 5% 6px;
            padding: 3px;
            border: 1px solid #cfcfcf;
            border-radius: 4px;
        }

        .ui-datepicker-year {
            margin: 0 15% 6px;
            padding: 3px;
            border: 1px solid #cfcfcf;
            border-radius: 4px;
        }
    }

    .ui-datepicker-today {
        border-radius: 4px;
        background-color: #e6eef1;

        a {
            color: #2a4982;
        }
    }

    table {
        width: 100%;

        td,
        th {
            text-align: center;
        }

        td {
            a {
                display: block;
                padding: 5px;
                border-radius: 4px;

                &:hover {
                    text-decoration: none;
                    color: #fff;
                    background-color: $primarycolor;
                }

                &.ui-state-active {
                    text-decoration: none;
                    color: #fff;
                    background-color: $primarycolor;
                }
            }
        }
    }
}

.gform_fields li.full {
    .ginput_container_fileupload {
        .gform_drop_area {
            position: relative;
            border: 1px dashed #d5d5d5;
            padding: 20px;
            text-align: center;

            .gform_button_select_files {
                @extend .btn;
                @extend .btn--primary;

                padding: 10px;
                margin: 10px;

                &::after {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .hidden__form {
        padding: 50px 70px 60px 70px;
    }
}

@media screen and (max-width: 767px) {
    .gform_fields > li {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .hidden__form {
        padding: 25px 30px 30px 30px;
    }
}
