.edits {
    padding: 120px 0;

    @media screen and (max-width: 1200px) {
        padding: 50px 0;
    }

    &__title {
        font-family: 'Conv_GraphikBlack';

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 0;
        }

        p {
            font-size: 24px;
            line-height: 32px;
            font-family: 'Conv_GraphikRegular';
            margin-bottom: 42px;

            @media screen and (max-width: 768px) {
                font-size: 18px;
                line-height: 28px;
                margin-bottom: 22px;
            }
        }
    }

    &__button {
        display: block;
        font-family: Conv_GraphikSemibold;
        font-size: 14px;
        line-height: 20px;
        border-bottom: solid 1px #054b96;
        width: max-content;
        margin: 0 auto;
        margin-top: 10px;
        transition: 0.3s ease-in-out all;

        &:hover {
            color: #0657af;
            border-color: transparent;
        }
    }

    &__wrapper {
        column-count: 4;
        column-gap: 30px;

        @media screen and (max-width: 1200px) {
            column-count: 2;
            column-gap: 10px;
        }

        .break-columns {
            column-span: all;
        }

        .edits__wrapper__item {
            display: grid;
            grid-template-rows: 1fr auto;
            break-inside: avoid;
            row-gap: 30px;

            @media screen and (max-width: 1200px) {
                row-gap: 10px;
            }

            /* stylelint-disable */
            @media screen and (min-width: 1200px) {
                &:nth-child(11n - 10) {
                    img,
                    video {
                        aspect-ratio: 4/3;
                    }
                }

                &:nth-child(11n - 9) {
                    img,
                    video {
                        aspect-ratio: 4/3.8;
                    }
                }

                &:nth-child(11n - 8) {
                    img,
                    video {
                        aspect-ratio: 4/3.4;
                    }
                }

                &:nth-child(11n - 7) {
                    img,
                    video {
                        aspect-ratio: 4/3.4;
                    }
                }

                &:nth-child(11n - 5) {
                    img,
                    video {
                        aspect-ratio: 4/3.8;
                    }
                }

                &:nth-child(11n - 4) {
                    img,
                    video {
                        aspect-ratio: 4/3;
                    }
                }

                &:nth-child(11n - 3) {
                    img,
                    video {
                        aspect-ratio: 4/3;
                    }
                }

                &:nth-child(11n - 2) {
                    img,
                    video {
                        aspect-ratio: 4/3.8;
                    }
                }
            }

            @media screen and (max-width: 1200px) {
                &:nth-child(5n - 4) {
                    img,
                    video {
                        aspect-ratio: 4/3;
                    }
                }

                &:nth-child(5n - 3) {
                    img,
                    video {
                        aspect-ratio: 4/3.8;
                    }
                }

                &:nth-child(5n - 2) {
                    img,
                    video {
                        aspect-ratio: 4/3.4;
                    }
                }

                &:nth-child(5n - 1) {
                    img,
                    video {
                        aspect-ratio: 4/3.4;
                    }
                }
            }
            /* stylelint-enable */
        }

        .block {
            display: block;
            width: 100%;
            height: auto;
            position: relative;
            border-radius: 3px;
            overflow: hidden;

            &:hover {
                .block__content {
                    opacity: 100%;
                    max-height: 150px;
                    margin-top: 5px;
                }
            }

            &__holder {
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                color: white;
                text-align: left;
                padding: 0 30px 24px 35px;
                font-size: 14px;

                @media screen and (max-width: 768px) {
                    padding: 0 16px 11px 16px;
                }
            }

            &__readmore {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 37px;
                min-width: 37px;
                height: 37px;
                transition: all 0.3s;
                border-radius: 100%;
                background: $primarycolor;

                svg {
                    width: 13px;
                    min-width: 13px;
                }
            }

            &__title {
                display: block;
                width: 100%;
                margin-bottom: 0;
                font-family: 'Conv_GraphikBlack';
                font-size: 24px;
                line-height: 32px;
                color: white;

                @media screen and (max-width: 768px) {
                    font-family: 'Conv_GraphikBold';
                    font-size: 14px;
                    line-height: 20px;
                }
            }

            &__content {
                opacity: 0;
                max-height: 0;
                transition: 0.3s ease-in-out all;
                display: flex;
                justify-content: space-between;
                align-items: center;
                column-gap: 10px;

                @media screen and (max-width: 1200px) {
                    display: none;
                }
            }

            &__summary {
                color: white;
                font-size: 14px;
            }

            &__media {
                height: 100%;
                width: 100%;
                transition: all 0.3s ease;
                position: relative;
                display: flex;

                &::before {
                    content: "";
                    background: rgba(0, 0, 0, 0.4);
                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.66) 0%, rgba(0, 0, 0, 0) 100%);
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }

                > * {
                    display: flex;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
