.archive__content--materialen {
    h1 {
        b,
        strong {
            margin-right: 0;
            margin-left: 10px;
        }
    }
}

@media screen and (max-width: 1199px) {

}

@media screen and (max-width: 991px) {
    .single__main--materialen {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 767px) {
    .single__main--materialen {
        margin-bottom: 0;
    }

    .archive__content--materialen {
        h1 {
            b,
            strong {
                margin-right: 0;
                margin-left: 3px;
            }
        }
    }
}

@media screen and (max-width: 575px) {

}
