.cases__btns {
    display: flex;
    column-gap: 16px;
    row-gap: 16px;
    margin-bottom: 42px;
    margin-top: 46px;

    @media screen and (max-width: 576px) {
        flex-direction: column;
    }

    &__col {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 12px;

        a {
            width: 100%;
        }

        span {
            font-style: italic;
            font-size: 14px;
            line-height: 20px;
            color: #374151;
        }
    }
}

.single__main .cases__overview {
    color: $primarycolor;
    border-bottom: 1px solid $primarycolor;
    font-size: 14px;
    line-height: 20px;
    font-family: Conv_GraphikSemibold;
    text-decoration: none;
    padding-bottom: 6px;
    transition: 0.3s ease-in-out all;

    &:hover {
        border-color: transparent;
    }

    svg {
        width: 13px;
        min-width: 13px;
        fill: $primarycolor;
        margin-right: 9px;
    }
}

@media screen and (max-width: 1199px) {

}

@media screen and (max-width: 991px) {

}

@media screen and (max-width: 767px) {

}

@media screen and (max-width: 575px) {

}
