.menu__open .header__menu {
    display: flex;
}

.header__menu {
    position: fixed;
    z-index: 999;
    inset: 0;
    margin-left: auto;
    height: 100%;
    transform-origin: top;
    flex-direction: column;
    overflow-y: auto;
    display: none;

    @media screen and (max-width: 1024px) {
        background: rgb(0, 0, 0);
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(9, 9, 9, 1) 100%);
        padding-top: 91px;
        padding-bottom: 2rem;
    }

    @media screen and (min-width: 1025px) {
        position: relative;
        margin-top: 0;
        margin-left: 20px;
        display: flex;
        justify-content: space-between;
        overflow-y: visible;
        padding-bottom: 0;
    }
}

.header__menu__button.container-fluid-full {
    @media screen and (min-width: 1024px) {
        display: none;
    }
}

.main__nav {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;

    @media screen and (min-width: 1025px) {
        flex-direction: row;
        height: 100%;
        margin-bottom: 0;
    }

    .hidden {
        display: none;
        height: 0;
        overflow: hidden;
    }
}

.nav-touch-item {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    display: none;
}

/* smartphones, touchscreens */
@media (hover: none) and (pointer: coarse) {
    .nav-touch-item {
        @media screen and (min-width: 1025px) {
            display: block;
        }
    }
}

/* stylus-based screens */
@media (hover: none) and (pointer: fine) {
    .nav-touch-item {
        @media screen and (min-width: 1025px) {
            display: block;
        }
    }
}

.nav__button {
    position: absolute;
    display: flex;
    align-items: center;
    height: 2.5rem;
    right: 0;
    padding: 0.75rem 1rem;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    background: none;
    border: none;

    @media screen and (min-width: 1025px) {
        display: none;
    }

    &:focus {
        outline: none;
    }

    &.toggled {
        svg {
            transform: rotate(180deg);
        }
    }

    svg {
        color: white;
        fill: currentColor;
        width: 11px;
    }
}

.main__nav__item {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;

    @media screen and (min-width: 1025px) {
        height: 100%;
        flex-direction: row;
        flex-basis: 0;
    }

    @media screen and (min-width: 1025px) {
        margin-left: 2.5px;
        margin-right: 2.5px;
    }

    @media screen and (min-width: 1400px) {
        margin-left: 10px;
        margin-right: 10px;
    }
}

.main__nav__item__link {
    color: white;
    display: flex;
    white-space: nowrap;
    transition-property: all;
    transition-duration: 150ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    align-items: center;
    height: 100%;
    height: 2.5rem;
    font-family: Conv_GraphikMedium;
    margin-bottom: 4px;
    padding: 0.75rem 1rem;
    border-left: solid 4px transparent;

    @media screen and (min-width: 1025px) {
        padding: 0.75rem 1rem;
        height: 52px;
        font-size: 14px;
        border-radius: 3px;
        margin-bottom: 6px;
    }

    @media screen and (min-width: 1400px) {
        padding: 0 1.5rem;
        font-size: 16px;
    }

    &:focus,
    &:hover {
        background: #191919;
        color: white;

        @media screen and (min-width: 1025px) {
            background: white;
            color: black;

            svg {
                color: black;
            }
        }
    }

    &.active {
        background: white;
        color: black;

        svg {
            color: black;
        }

        @media screen and (max-width: 1024px) {
            background: rgb(255, 255, 255);
            background: linear-gradient(90deg, rgba(255, 255, 255, 0.22) 0%, rgba(255, 255, 255, 0) 100%);
            color: white;
            border-color: white;
        }
    }
}

.main__nav__item__link__arrow {
    display: none;
    height: 1.5rem;

    @media screen and (min-width: 1025px) {
        display: flex;
    }

    svg {
        color: white;
        fill: currentColor;
        width: 10px;
        margin-left: 10px;
    }
}

.submenu__nav {
    padding-left: 0;
    flex-direction: column;

    @media screen and (min-width: 1025px) {
        position: absolute;
        top: 100%;
        z-index: 10;
        background: white;
        border-width: 1px;
        border-color: rgba(107, 114, 128, 0.1);
        border-radius: 3px;
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
        padding: 20px 44px;
    }

    &.media {
        flex-direction: row;
        padding: 0;
        overflow: hidden;
    }
}

.submenu__nav__items {
    @media screen and (max-width: 1024px) {
        width: 100%;
    }

    @media screen and (min-width: 1025px) {
        width: max-content;
        padding: 20px 44px;
    }
}

.submenu__nav__media {
    height: auto;
    width: 100%;
    align-self: stretch;

    @media screen and (max-width: 1024px) {
        display: none;
    }

    video {
        width: 671px;
        min-height: 350px;
        max-width: 40vw;
        display: none;
        /* stylelint-disable */
        aspect-ratio: 16/9;
        /* stylelint-enable */
        object-fit: cover;

        &.active {
            display: block;
            height: 100%;
        }
    }

    img {
        width: 671px;
        min-height: 350px;
        max-width: 40vw;
        display: none;
        /* stylelint-disable */
        aspect-ratio: 16/9;
        /* stylelint-enable */
        object-fit: cover;

        &.active {
            display: block;
            height: 100%;
        }
    }
}

.submenu__nav__item {
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    width: 100%;

    @media screen and (min-width: 1025px) {
        &:first-child {
            border-top-left-radius: 0.5rem;
            border-top-right-radius: 0.5rem;
        }

        &:last-child {
            border-bottom-right-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
        }
    }
}

.submenu__nav__item__link {
    display: flex;
    transition-property: all;
    transition-duration: 150ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
    align-items: center;
    height: 2.5rem;
    color: white;
    padding: 0.75rem 1rem;
    border-left: solid 4px transparent;

    @media screen and (min-width: 1025px) {
        padding-left: 0;
        padding-right: 0;
        color: #191919;
        white-space: nowrap;
        font-family: Conv_GraphikMedium;
    }

    &:hover {
        @media screen and (min-width: 1025px) {
            color: $primarycolor;

            svg {
                color: $primarycolor;
            }
        }

        @media screen and (max-width: 1024px) {
            background: #191919;
            color: white;
        }
    }

    &.active {
        @media screen and (min-width: 1025px) {
            color: $primarycolor;

            svg {
                color: $primarycolor;
            }
        }

        @media screen and (max-width: 1024px) {
            background: rgb(255, 255, 255);
            background: linear-gradient(90deg, rgba(255, 255, 255, 0.22) 0%, rgba(255, 255, 255, 0) 100%);
            color: white;
            border-color: white;
        }
    }
}

.submenu__nav__item__link__arrow {
    display: flex;
    align-self: start;

    svg {
        justify-self: start;
        color: #9ca3af;
        fill: currentColor;
        width: 0.375rem;
        margin-right: 12px;

        @media screen and (max-width: 1024px) {
            width: 4.5px;
            color: white;
        }
    }
}

.submenu__child {
    padding-left: 0;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1025px) {
        position: absolute;
        top: 0;
        left: 100%;
        z-index: 10;
        background: white;
        border-width: 1px;
        border-color: rgba(249, 250, 251, 0.05);
        border-radius: 3px;
        width: 12rem;
        padding: 20px 44px;
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);

        &.active {
            background: #f9fafb;
        }
    }
}

.submenu__child__item {
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 400;
    width: 100%;

    @media screen and (min-width: 1025px) {
        &:first-child {
            border-top-left-radius: 0.5rem;
            border-top-right-radius: 0.5rem;
        }

        &:last-child {
            border-bottom-right-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
        }
    }
}

.submenu__child__item__link {
    color: white;
    display: flex;
    transition-property: all;
    transition-duration: 150ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    align-items: center;
    height: 2.5rem;

    @media screen and (max-width: 1024px) {
        padding: 0.75rem 1rem 0.75rem 2rem;
        border-left: solid 4px transparent;

        &:hover {
            background: #191919;
            color: white;
        }
    }

    &.active {
        @media screen and (max-width: 1024px) {
            background: rgb(255, 255, 255);
            background: linear-gradient(90deg, rgba(255, 255, 255, 0.22) 0%, rgba(255, 255, 255, 0) 100%);
            color: white;
            border-color: white;
        }
    }

    @media screen and (min-width: 1025px) {
        color: black;
        padding: 0.75rem 0;
        padding-left: 0;
        padding-right: 0;
        font-family: Conv_GraphikMedium;

        &:hover {
            color: $primarycolor;

            svg {
                color: $primarycolor;
            }
        }

        &.active {
            color: $primarycolor;

            svg {
                color: $primarycolor;
            }
        }
    }
}

.submenu__child__item__link__arrow {
    display: flex;
    align-self: start;

    svg {
        justify-self: start;
        color: #9ca3af;
        fill: currentColor;
        width: 0.375rem;
        margin-right: 12px;

        @media screen and (max-width: 1024px) {
            color: white;
            width: 4.5px;
        }
    }
}

.menu__toggler {
    display: flex;
    align-items: center;
    height: 35px;
    padding: 0 12px 0 34px;
    cursor: pointer;
    transition: all 0.3s;
    font-family: Conv_GraphikBold;
    font-size: 14px;
    line-height: normal;
    color: white;
    outline: 0;
    background: $primarycolor url('../../svg/bars.svg') no-repeat 14px center;
    background-size: 13px 13px;

    &:hover {
        background-color: #003976;
    }
}

.menu__open {
    .menu__toggler {
        background: $primarycolor url('../../svg/times.svg') no-repeat 14px center;
        background-size: 13px 13px;

        &:hover {
            background-color: #003976;
        }
    }
}
