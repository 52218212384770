/** Import Bootstrap functions */
@import "~bootstrap/scss/functions";

$slick-loader-path: "~slick-carousel/slick/";
$slick-font-path: "~slick-carousel/slick/fonts/";

$primarycolor: #054b96;
$secondarycolor: #577493;
$tertiarycolor: #eb7f00;

$font-primary: 'Conv_GraphikRegular';
$font-secondary: '';
$body-font-size: 18px;
$body-font-weight: 400;
$body-line-height: 1.7;
$body-font-color: #374151;

// Headings: h1,h2,h3,h4,h5,h6
$headings-margin-bottom: 20px;
$headings-font-family: 'Conv_GraphikBlack';
$headings-font-weight: 400;
$headings-line-height: 1.3;
$headings-color: #191919;

// Header:
$header-height: 130px;

$border-radius: 5px;
$block-radius: 20px;

$theme-colors: (
    primary: $primarycolor
);

/** Bootstrap navbar fix (https://git.io/fADqW) */
$navbar-dark-toggler-icon-bg: none;
$navbar-light-toggler-icon-bg: none;

//Bootstrap
$grid-gutter-width: 40px !default;

$enable-responsive-font-sizes: true;
$h1-font-size: 48px;
$h2-font-size: 36px;
$h3-font-size: 27px;
$h4-font-size: 22px;
$h5-font-size: 18px;
