.news {
    background-color: black;
    padding-top: 112px;
    padding-bottom: 126px;
    overflow: hidden;

    @media screen and (max-width: 768px) {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .row {
        align-items: center;
    }

    &__highlighted {
        color: white;
        display: grid;
        grid-template-columns: repeat(12, minmax(0, 1fr));

        &:focus,
        &:hover {
            color: white;
        }

        @media screen and (max-width: 1200px) {
            margin-bottom: 57px;
        }

        &__holder {
            display: flex;
            justify-content: center;
            flex-direction: column;
            grid-column: span 5 / span 5;

            @media screen and (max-width: 1400px) {
                grid-column: span 6 / span 6;
            }

            @media screen and (max-width: 768px) {
                grid-column: span 12/ span 12;
            }

            &__subtitle {
                color: #7a7a7a;
                font-size: 24px;
                line-height: 32px;
                margin-bottom: 29px;
            }

            &__title {
                font-size: 64px;
                line-height: 64px;
                font-family: Conv_GraphikBold;
                width: 175%;
                position: relative;
                z-index: 1;
                margin-bottom: 34px;

                @media screen and (max-width: 1400px) {
                    font-size: 48px;
                    line-height: 48px;
                }

                @media screen and (max-width: 1200px) {
                    width: 125%;
                }

                @media screen and (max-width: 1024px) {
                    font-size: 36px;
                    line-height: 40px;
                    font-family: Conv_GraphikBlack;
                }

                @media screen and (max-width: 768px) {
                    width: 100%;
                    margin-bottom: 22px;
                }
            }

            &__date {
                color: #7a7a7a;
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 14px;
            }

            &__content {
                font-size: 18px;
                line-height: 28px;
            }
        }

        &__image {
            grid-column: span 7 / span 7;
            width: 100%;
            margin-left: auto;
            position: relative;
            padding-left: 40px;
            z-index: 0;
            /* stylelint-disable */
            aspect-ratio: 3/4;
            /* stylelint-enable */

            @media screen and (max-width: 1400px) {
                grid-column: span 6 / span 6;
            }

            @media screen and (max-width: 768px) {
                grid-column: span 12/ span 12;
                padding-left: 0;
                /* stylelint-disable */
                aspect-ratio: 4/3;
                /* stylelint-enable */
            }

            img {
                height: 100%;
                object-fit: cover;
                object-position: center;
            }

            &__button {
                background: black;
                position: absolute;
                bottom: 0;
                left: 40px;
                padding: 24px 30px;
                font-family: Conv_GraphikSemibold;
                font-size: 18px;
                line-height: 28px;
                transition: 0.3s ease-in-out all;

                &:hover {
                    color: black;
                    background: white;

                    svg path {
                        fill: black;
                    }
                }

                @media screen and (max-width: 768px) {
                    left: 0;
                    padding: 20px;
                    font-size: 16px;
                    line-height: 24px;
                }

                svg {
                    margin-left: 20px;
                    width: 21px;
                    min-width: 21px;

                    @media screen and (max-width: 768px) {
                        width: 18px;
                        min-width: 18px;
                    }
                }
            }
        }
    }

    &__item {
        color: white;
        display: flex;
        flex-direction: column;

        &:focus,
        &:hover {
            color: white;

            .news__item__title svg {
                transform: translateX(15px);

                path {
                    fill: #0657af;
                }
            }
        }

        @media screen and (min-width: 1200px) {
            margin-left: 80px;
        }

        &:not(:last-child) {
            padding-bottom: 45px;
            margin-bottom: 30px;
            border-bottom: solid 1px #3a3a3a;

            @media screen and (max-width: 1024px) {
                padding-bottom: 15px;
                margin-bottom: 28px;
            }
        }

        &__title {
            font-size: 36px;
            line-height: 46px;
            margin-bottom: 9px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            column-gap: 28px;

            svg {
                width: 21px;
                min-width: 21px;
                transition: 0.3s ease-in-out transform;

                path {
                    transition: 0.3s ease-in-out all;
                }
            }

            @media screen and (max-width: 1024px) {
                font-size: 20px;
                line-height: 28px;
                margin-bottom: 9px;
            }
        }

        &__date {
            color: #7a7a7a;
            font-size: 14px;
            line-height: 20px;
        }
    }
}
