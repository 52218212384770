.bewerkingen__btns {
    display: flex;
    column-gap: 16px;
    row-gap: 16px;
    margin-bottom: 42px;
    margin-top: 46px;

    @media screen and (max-width: 576px) {
        flex-direction: column;
    }

    &__col {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 12px;

        a {
            width: 100%;
        }

        span {
            font-style: italic;
            font-size: 14px;
            line-height: 20px;
            color: #374151;
        }
    }
}

.bewerkingen__extra__content {
    margin-top: 40px;
}

.bewerking__imgs__content {
    display: flex;
    align-items: flex-start;
    flex: 1;
    flex-direction: column;
    padding: 38px 42px;
    text-align: center;
}

.bewerking__imgs__title {
    display: block;
    width: 100%;
    margin-bottom: 0;
    font-family: 'Conv_GraphikBold';
    font-size: 18px;
    line-height: normal;
    color: $primarycolor;
}

.bewerking__imgs__summary {
    display: block;
    width: 100%;
    height: 100%;
    margin: 14px 0 0;
    font-size: 17px;
    line-height: 1.4;
    color: $body-font-color;
}

.single__images--bewerkingen {
    .slick-track {
        display: flex !important;
    }

    .slick-slide {
        height: inherit !important;

        > div {
            height: 100%;

            a {
                height: 100%;

                .slider__content__item__inner {
                    position: relative;
                    float: left;
                    height: 100%;
                    background-color: #f2f2f2;
                }
            }
        }
    }
}

.slider__content--cases {
    .slick-list::after {
        display: none;
    }
}

.bewerkingen__cases__items {
    margin-top: 100px;
}

.bewerkingen__cases__items__title {
    margin-bottom: 30px;
    font-family: $headings-font-family;
    font-size: 28px;
    color: $primarycolor;
}

.bewerking__cases__item {
    background-color: #004996;
}

.bewerking__cases__item__inner {
    display: flex;
    flex-wrap: wrap;
}

.bewerking__cases__item__right {
    position: relative;
    width: 100%;
    flex: 0 0 48%;
    max-width: 48%;
}

.bewerking__cases__item__right__img {
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.bewerking__cases__item__left {
    position: relative;
    width: 100%;
    height: 100%;
    flex: 0 0 52%;
    max-width: 52%;
    color: #fff;
    padding: 63px 75px;
    font-size: 22px;
    background: url('../../../images/bg-wielen.png') no-repeat left top;
}

.bewerking__cases__item__title {
    font-family: $headings-font-family;
    font-size: 30px;
    margin-bottom: 12px;
}

.koppelingen {
    margin-bottom: 96px;

    @media screen and (max-width: 1024px) {
        margin-bottom: 50px;
    }

    h2 {
        text-align: center;
    }

    .block {
        background: #f4f6fb;
        padding: 40px 46px;
        height: 100%;
        display: block;

        &:hover {
            .block__readmore {
                background: $secondarycolor;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
        }

        &__title {
            color: #191919;
            font-size: 24px;
            line-height: 32px;
            font-family: Conv_GraphikBold;
            margin-bottom: 16px;
        }

        &__summary {
            color: #374151;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 20px;
        }

        &__readmore {
            background: $primarycolor;
            width: 37px;
            height: 37px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            transition: 0.2s ease-in-out background;

            svg {
                width: 13px;
                min-width: 13px;
            }
        }
    }

    .row {
        row-gap: 40px;
    }
}

@media screen and (max-width: 1199px) {

}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 575px) {
}
