/* stylelint-disable */
@media screen and (max-width: 1199px) {
    .max-xl-slideInRight {
        -webkit-animation-name: slideInRight;
        animation-name: slideInRight;
    }
}

@media screen and (min-width: 1200px) {
    .xl-fadeInUp {
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
    }
}
/* stylelint-enable */

@font-face {
    font-family: 'Conv_GraphikLight';
    src: url('../../fonts/GraphikLight.eot');
    src: local('☺'), url('../../fonts/GraphikLight.woff') format('woff'), url('../../fonts/GraphikLight.ttf') format('truetype'), url('../../fonts/GraphikLight.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Conv_GraphikRegular';
    src: url('../../fonts/GraphikRegular.eot');
    src: local('☺'), url('../../fonts/GraphikRegular.woff') format('woff'), url('../../fonts/GraphikRegular.ttf') format('truetype'), url('../../fonts/GraphikRegular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Conv_GraphikMedium';
    src: url('../../fonts/GraphikMedium.eot');
    src: local('☺'), url('../../fonts/GraphikMedium.woff') format('woff'), url('../../fonts/GraphikMedium.ttf') format('truetype'), url('../../fonts/GraphikMedium.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Conv_GraphikSemibold';
    src: url('../../fonts/GraphikSemibold.eot');
    src: local('☺'), url('../../fonts/GraphikSemibold.woff') format('woff'), url('../../fonts/GraphikSemibold.ttf') format('truetype'), url('../../fonts/GraphikSemibold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Conv_GraphikBold';
    src: url('../../fonts/GraphikBold.eot');
    src: local('☺'), url('../../fonts/GraphikBold.woff') format('woff'), url('../../fonts/GraphikBold.ttf') format('truetype'), url('../../fonts/GraphikBold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Conv_GraphikBlack';
    src: url('../../fonts/Graphik-Black.eot');
    src: local('☺'), url('../../fonts/Graphik-Black.woff') format('woff'), url('../../fonts/Graphik-Black.ttf') format('truetype'), url('../../fonts/Graphik-Black.svg') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: normal;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: $font-primary;
    font-size: $body-font-size;
    font-weight: $body-font-weight;
    line-height: $body-line-height;
    color: $body-font-color;
}

.main {
    padding: 0;
}

.svg-inline--fa {
    width: 30px;
    height: auto;
}

ul {
    &.check {
        margin: 0 0 30px 0;
        padding: 0;
        list-style: none;

        li {
            position: relative;
            margin-bottom: 2px;
            padding-left: 37px;

            &:last-child {
                margin-bottom: 0;
            }

            &::before {
                position: absolute;
                top: 5px;
                left: 0;
                width: 28px;
                height: 28px;
                content: '';
                background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23054b96' d='M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z'%3E%3C/path%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-size: 18px;
            }
        }
    }
}

h1 {
    margin-bottom: 30px;

    b,
    strong {
        margin-right: 10px;
        padding: 8px 20px;
        color: $white;
        background: $primarycolor;
    }
}

p a {
    transition: all 300ms ease-in-out;
    border-bottom: 1px solid $primarycolor;

    &:hover,
    &:focus {
        border-color: transparent;
    }
}

a {
    transition: all 300ms ease-in-out;

    &:hover,
    &:focus {
        text-decoration: none;
        color: $primarycolor;
    }
}

p {
    margin-bottom: 30px;
}

b,
strong {
    font-family: 'Conv_GraphikBold';
}

.acf-map {
    width: 100%;
    height: 500px;
    margin: 0;
}

.acf-map img {
    max-width: inherit !important;
}

.archive__content {
    padding: 0 0 40px;

    h1 {
        margin-bottom: 32px;
    }
}

.single-bewerkingen .archive__list .btn--hollow {
    display: flex;
    width: max-content;
    margin: 0 auto;
}

.single-bewerkingen .archive__list,
.page-template-cases .archive__list,
.blog .archive__list {
    padding: 96px 0;
    background: black;
    overflow: hidden;

    h1,
    h2,
    h3,
    h4 {
        color: white;
        margin-bottom: 58px;
    }

    @media screen and (max-width: 768px) {
        padding: 50px 0;
    }
}

.single-materialen .archive__list,
.single-bewerkingen .archive__list {
    margin: 96px 0;

    @media screen and (max-width: 768px) {
        margin: 50px 0;
    }

    &.bewerkingen {
        h1,
        h2,
        h3,
        h4 {
            text-align: center;
        }
    }
}

.slider__content {
    position: relative;

    a {
        position: relative;
        float: left;
    }

    .slick-arrow {
        z-index: 1;
        right: 30px;
        overflow: hidden;
        width: 53px;
        height: 89px;
        opacity: 1;
        background: url('../../images/arrow-right.png') no-repeat;
        background-size: cover;
    }

    .slick-list {
        &::after {
            position: absolute;
            top: 0;
            right: 0;
            display: inline-block;
            width: 80px;
            height: 100%;
            content: "";
            pointer-events: none;
            background: linear-gradient(to right, rgba($white, 0) 0%, $white 100%);
        }
    }

    .slick-prev {
        display: none !important;
    }

    .slick-track {
        margin-left: 0;
    }
}

.slider__content__item {
    padding: 0 30px 0 0;
}

.slider__wrapper {
    overflow: hidden;
}

.slider__wrapper__cases {
    position: relative;
}

.slider__wrapper__top {
    position: relative;
}

.slider__play {
    position: absolute;
    z-index: 9;
    top: 0;
    left: -15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    pointer-events: none;

    svg {
        width: 50px;
        height: 50px;
        color: #fff;
    }
}

.slider__top__prev__cases,
.slider__top__next__cases {
    position: absolute;
    z-index: 9;
    top: 50%;
    margin-top: -28px;

    svg {
        width: 56px;
        height: 56px;
        cursor: pointer;
        color: #bfbfbf;
        transition: all 0.3s;
    }

    &:hover {
        svg {
            color: #7a7a7a;
        }
    }
}

.slider__top__prev__cases {
    left: -85px;
}

.slider__top__next__cases {
    right: -85px;
}

.slider__top__prev,
.slider__top__next {
    position: absolute;
    z-index: 9;
    top: 50%;
    margin-top: -40px;

    svg {
        width: 80px;
        height: 80px;
        cursor: pointer;
        color: rgba(#fff, 0.7);
    }
}

.slider__top__prev {
    left: 10px;
}

.slider__top__next {
    right: 10px;
}

.slick-slide {
    cursor: pointer;

    &.slick-current {
        cursor: default;
    }

    a {
        &:focus {
            outline: none !important;
        }
    }

    &:focus {
        outline: none !important;
    }

    img {
        outline: none !important;

        &:focus {
            outline: none !important;
        }
    }
}

.slider__nav {
    margin: 10px -5px 0;
    padding: 0 1px;
}

.slider__nav__item {
    position: relative;
    padding: 0 4px;

    .slider__play {
        svg {
            width: 25px;
            height: 25px;
        }
    }
}

//.block {
//    position: relative;
//    display: flex;
//    flex-direction: column;
//    overflow: hidden;
//    height: 100%;
//    text-align: center;
//    background-color: #f2f2f2;
//
//    &:hover {
//        .block__readmore {
//            background-color: lighten($secondarycolor, 5) !important;
//        }
//    }
//}
//
//.block__img {
//    position: relative;
//    display: block;
//}
//
//.block__img__inner {
//    display: block;
//    overflow: hidden;
//    width: 100%;
//
//    img {
//        width: 100%;
//        max-width: 100%;
//        transition: all 0.3s ease;
//    }
//}
//
//.block__holder {
//    position: absolute;
//    bottom: 0;
//    left: 0;
//    color: white;
//    text-align: left;
//    padding: 0 42px 24px;
//    font-size: 14px;
//    //display: flex;
//    //align-items: flex-start;
//    //flex: 1;
//    //flex-direction: column;
//    //padding: 50px 45px;
//
//    .btn {
//        position: relative;
//        display: inline-block;
//        margin-top: auto;
//        transition: all 0.3s;
//        font-size: 13px;
//
//        svg {
//            width: 7px;
//            height: 18px;
//            margin-top: 2px;
//            margin-left: 10px;
//            transition: all 0.3s ease;
//        }
//    }
//
//    &.block__content--locaties {
//        padding: 65px 45px;
//    }
//}
//
//.block__title {
//    display: block;
//    width: 100%;
//    margin-bottom: 0;
//    font-family: 'Conv_GraphikBlack';
//    font-size: 24px;
//    line-height: 32px;
//    color: white;
//}
//
//.block__subtitle {
//    margin-top: 5px;
//    margin-bottom: 20px;
//    font-weight: 600;
//}
//
//.block__phone,
//.block__email {
//    position: relative;
//    display: flex;
//    align-items: center;
//    padding-left: 34px;
//    word-break: break-all;
//
//    svg {
//        position: absolute;
//        top: 4px;
//        left: 0;
//        width: 16px;
//        height: 16px;
//        color: $primarycolor;
//        fill: $primarycolor;
//    }
//}
//
//.block__phone {
//    margin-bottom: 4px;
//}
//
//.block__no__img {
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    min-height: 150px;
//    background-color: #f9f9f9;
//}
//
//.block__date {
//    display: block;
//    font-size: 14px;
//    color: #505050;
//    margin-top: 5px;
//    font-family: "Conv_GraphikSemibold";
//
//    svg {
//        width: 12px;
//        height: 13px;
//        margin: -4px 8px 0 0;
//        color: #505050;
//    }
//}
//
//.block__summary {
//    display: block;
//    height: 100%;
//    margin: 14px 0 0;
//    font-size: 19px;
//    line-height: 1.4;
//    color: $body-font-color;
//}
//
//.block__list {
//    padding: 19px 0 0;
//    line-height: 24px;
//}
//
//.block__item {
//    position: relative;
//    display: block;
//    margin: 0 0 8px 20px;
//    font-size: 19px;
//    color: #464646;
//
//    &:last-child {
//        margin-bottom: 0;
//    }
//
//    &::before {
//        position: absolute;
//        top: 5px;
//        left: -18px;
//        width: 6px;
//        height: 18px;
//        content: "";
//        background: url('../../images/angle-right-regular.svg') no-repeat;
//        background-size: 6px 16px;
//    }
//}
//
//.block__readmore {
//    position: absolute;
//    right: 0;
//    bottom: 0;
//    width: 30px;
//    height: 30px;
//    transition: all 0.3s;
//    background: $secondarycolor url('../../svg/chevron-right.svg') no-repeat center;
//    background-size: 8px 14px;
//}
//
//.block--noimage {
//    background: $primarycolor;
//
//    .block__title {
//        color: $white;
//    }
//
//    .block__summary {
//        line-height: 1.5;
//        color: $white;
//    }
//}
//
//.block--noimage .block__content {
//    padding-right: 33px;
//    padding-left: 33px;
//}

.main__inner {
    padding-top: 110px;

    .btn--prev {
        margin-top: 30px;
    }

    h3 {
        margin-top: 30px;
    }
}

.accordion {
    margin: auto;
}

// Custom checkbox styling
.checkbox {
    display: flex;
    align-items: center;
    text-align: left;
}

.checkbox__input {
    display: none;
}

.checkbox__label {
    position: relative;
    display: flex;
    margin: 0;
    padding-right: 20px;
    cursor: pointer;
    line-height: 1.5;

    &::before {
        position: relative;
        display: inline-block;
        width: 15px;
        min-width: 15px;
        height: 15px;
        margin: 6px 10px 0;
        content: "";
        cursor: pointer;
        border: 1px solid #d5d5d5;
        border-radius: 3px;
        background-color: transparent;
    }
}

.checkbox .checkbox__input:checked + .checkbox__label::after {
    position: absolute;
    top: 8px;
    left: 15px;
    display: block;
    width: 5px;
    height: 9px;
    content: "";
    transform: rotate(45deg);
    border: solid #0079bf;
    border-width: 0 2px 2px 0;
}

.project__detail h1 {
    margin-bottom: 7px;
}

.video--play {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    transform: translate(-50%, -50%);
    background: url('../../svg/play-circle.svg') no-repeat;
}

.single__main {
    > * {
        &:last-child {
            margin-bottom: 0;
        }
    }

    .btn {
        text-decoration: inherit;
    }
}

.single__images {
    margin-top: 65px;

    h3 {
        margin-bottom: 35px;
    }
}

.CybotCookiebotDialogBodyButton {
    margin-bottom: 10px !important;
    padding: 8px 18px 10px !important;
    font-size: 13px !important;
    border-radius: 4px !important;
}

#CybotCookiebotDialogBodyContent {
    margin-top: 6px !important;
    padding-top: 12px !important;
}

#CybotCookiebotDialogPoweredbyLink {
    margin-top: 16px !important;
}

#CybotCookiebotDialogDetail {
    margin-bottom: 20px !important;
}

#CybotCookiebotDialogBodyLevelButtonAccept {
    margin-top: 5px !important;
    padding: 4px 18px 6px !important;
    font-size: 13px !important;
    border-radius: 3px !important;
}

#CybotCookiebotDialogBodyLevelButtonsTable {
    margin: 5px 0 15px 0 !important;
}

//@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
//    .block {
//        display: block;
//        flex: none;
//    }
//
//    .block__content {
//        display: block;
//    }
//}

@media screen and (max-width: 1650px) {
    .slider__top__prev__cases {
        left: 7px;
    }

    .slider__top__next__cases {
        right: 7px;
    }
}

//@media screen and (max-width: 1599px) {
//    .block__item {
//        font-size: 18px;
//    }
//
//    .block__title {
//        font-size: 22px;
//    }
//
//    .block__summary {
//        font-size: 18px;
//    }
//}

@media (max-width: 1199px) {
    //.block__content {
    //    padding: 35px;
    //
    //    &.block__content--locaties {
    //        padding: 50px 35px;
    //    }
    //}
    //
    //.block--noimage .block__content {
    //    padding-right: 20px;
    //    padding-left: 20px;
    //}
    //
    //.block__item {
    //    font-size: 17px;
    //}
    //
    //.block__title {
    //    font-size: 20px;
    //}
    //
    //.block__summary {
    //    font-size: 17px;
    //}

    .slider__top__prev,
    .slider__top__next {
        margin-top: -30px;
    }

    .slider__top__prev svg,
    .slider__top__next svg {
        width: 60px;
        height: 60px;
        cursor: pointer;
        color: rgba(255, 255, 255, 0.7);
    }

    .slider__top__prev {
        left: 10px;
    }

    .slider__top__next {
        right: 10px;
    }
}

@media screen and (max-width: 991px) {
    .slider__top__prev__cases,
    .slider__top__next__cases {
        top: 120px;
        margin-top: auto;

        svg {
            color: rgba(#fff, 0.7);
        }

        &:hover {
            svg {
                color: #fff;
            }
        }
    }

    //.block__item {
    //    font-size: 16px;
    //}
    //
    //.block__title {
    //    font-size: 18px;
    //}
    //
    //.block__summary {
    //    font-size: 16px;
    //}

    .archive__content {
        padding: 0 0 20px;
    }

    .single__images {
        margin-top: 50px;

        h3 {
            margin-bottom: 25px;
        }
    }

    .main__inner {
        padding: 55px 0 90px 0;
    }
}

@media (max-width: 767px) {
    html body {
        font-size: 15px;
    }

    h1 {
        margin-bottom: 20px;
        font-size: 24px;

        b,
        strong {
            margin-right: 3px;
            padding: 4px 10px;
        }
    }

    p {
        margin-bottom: 20px;
    }

    .main__inner {
        padding: 40px 0;
    }

    .archive__content {
        padding: 0 0 15px;
        //text-align: center;
    }

    .archive__content h1 {
        display: block;
        margin-bottom: 15px;

        &::after {
            display: none;
        }
    }

    //.block__item {
    //    font-size: 15px;
    //}
    //
    //.block__content {
    //    padding: 30px 30px 20px 30px;
    //
    //    &.block__content--locaties {
    //        padding: 30px 30px 20px 30px;
    //    }
    //}
    //
    //.block--noimage .block__content {
    //    padding-right: 20px;
    //    padding-left: 20px;
    //}
    //
    //.block__summary {
    //    margin: 15px 0 15px;
    //    font-size: 15px;
    //}

    .layer {
        .archive__list {
            padding-top: 0;
        }
    }

    .single__images {
        margin-top: 30px;

        h3 {
            margin-bottom: 20px;
        }
    }

    .slider__top__prev__cases,
    .slider__top__next__cases {
        top: 74px;
    }
}

@media screen and (max-width: 575px) {
    html body {
        font-size: 15px;
    }

    //.block__item {
    //    padding-left: 22px;
    //    font-size: 14px;
    //
    //    &::before {
    //        background-size: 11px 11px;
    //    }
    //}
    //
    //.block__title {
    //    font-size: 17px;
    //}
    //
    //.block__summary {
    //    font-size: 14px;
    //}

    .slider__content__item {
        padding: 0;
    }

    .slider__content .slick-arrow {
        right: 0;
    }

    .slider__play {
        left: 0;
    }

    #CybotCookiebotDialogBody {
        padding-right: 17px !important;
        padding-left: 17px !important;
    }

    #CybotCookiebotDialogBodyLevelButtonAccept {
        margin-bottom: 5px !important;
    }
}

@media screen and (max-width: 359px) {
    .single__images {
        h3 {
            font-size: 20px;
        }
    }
}
