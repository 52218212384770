@import "common/variables";

/** Import everything from autoload */
@import "./autoload/_bootstrap.scss";

/**
* Import npm dependencies
*
* Prefix your imports with `~` to grab from node_modules/
* @see https://github.com/webpack-contrib/sass-loader#imports
*/
// @import "~some-node-module";

// Import Slick from node_modules
@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";

// Import fontawesome via package manager
// @import "~@fortawesome/fontawesome-pro/css/all.css";

// Import Fancybox from node_modules
@import "~@fancyapps/fancybox/dist/jquery.fancybox.min.css";

// Import Animate.css from node_modules

@import "~wow.js/css/libs/animate.css";

/** Import theme styles */
@import "common/global";
@import "components/buttons";
@import "components/404";
@import "components/comments";
@import "components/forms";
@import "components/wp-classes";
@import "components/social-icons";
@import "components/fancybox";
@import "layouts/header";
@import "layouts/banner";
@import "layouts/search";
@import "layouts/page-content";
@import "layouts/menu";
@import "layouts/sidebar";
@import "layouts/methods";
@import "layouts/team";
@import "layouts/footer";
@import "layouts/nieuws";
@import "layouts/pages";
@import "layouts/tinymce";

/** Autoload modules */
@import "modules/front/_edits.scss"; @import "modules/front/_materials.scss"; @import "modules/front/_news.scss"; @import "modules/front/_textusps.scss";
@import "modules/global/_bewerkingen.scss"; @import "modules/global/_cases.scss"; @import "modules/global/_contact.scss"; @import "modules/global/_download.scss"; @import "modules/global/_locaties.scss"; @import "modules/global/_materialen.scss"; @import "modules/global/_team.scss";

/** Import responsive styles */
@import "layouts/responsive-1199px+";
@import "layouts/responsive-1199px";
@import "layouts/responsive-991px";
@import "layouts/responsive-767px";
@import "layouts/responsive-575px";
@import "layouts/responsive-575px-";
