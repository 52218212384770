.textusps {
    background: #f4f6fb;
    padding: 140px 0 125px;

    @media screen and (max-width: 1199px) {
        overflow-x: hidden;
    }

    @media screen and (max-width: 1024px) {
        padding: 50px 0;
    }
}

.textusps__title {
    margin-bottom: 19px;
    color: #191919;
    line-height: 1;

    @media screen and (max-width: 1024px) {
        font-size: 30px;
        line-height: 36px;
    }
}

.textusps__content {
    padding-right: 160px;

    @media screen and (max-width: 1450px) {
        padding-right: 80px;
    }

    @media screen and (max-width: 1200px) {
        padding-right: 0;
    }
}

.textusps__desc {
    font-family: 'Conv_GraphikRegular';
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 20px;
    color: #374151;

    @media screen and (max-width: 1024px) {
        font-size: 16px;
        line-height: 24px;
    }
}

.textusps__usp {
    @media screen and (min-width: 1200px) {
        display: grid;
        column-gap: 30px;
        row-gap: 30px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &.slick-slider {
        margin-top: 30px;
        margin-left: -20px;
        margin-right: -20px;
    }

    .slick-track {
        display: flex;
        column-gap: 7px;
    }

    .slick-list {
        overflow: visible;
    }

    .slick-slide {
        height: inherit;

        & > div {
            height: 100%;

            .textusps__usp__item {
                height: 100%;
            }
        }
    }
}

.textusps__usp__slider {
    &--prev {
        left: 0;
        position: absolute;
        top: calc(50% + 15px);
        transform: translateY(-50%);
        background: #f4f6fb;
        border: none;
        border-radius: 3px;
        width: 40px;
        height: 40px;

        @media screen and (min-width: 1200px) {
            display: none;
        }

        &:focus {
            outline: none;
        }

        svg {
            width: 6px;
        }
    }

    &--next {
        right: 0;
        position: absolute;
        top: calc(50% + 15px);
        transform: translateY(-50%);
        background: #f4f6fb;
        border: none;
        border-radius: 3px;
        width: 40px;
        height: 40px;

        @media screen and (min-width: 1200px) {
            display: none;
        }

        &:focus {
            outline: none;
        }

        svg {
            width: 6px;
        }
    }
}

.textusps__usp__item {
    background: white;
    padding: 20px 35px 20px 40px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;

    @media screen and (min-width: 1200px) {
        padding: 20px;
    }

    @media screen and (min-width: 1300px) {
        padding: 30px 40px 35px 45px;
    }
}

.textusps__usp__item__img {
    display: flex;
    height: 46px;
    margin-bottom: 11px;

    img {
        max-width: 100%;
        transition: all 0.3s;
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .textusps__usp__item__img--documents {
        max-width: 99px !important;
    }
}

.textusps__usp__title {
    font-family: 'Conv_GraphikBold';
    font-size: 20px;
    line-height: 28px;
    color: #191919;
    margin-bottom: 11px;
}

.textusps__usp__desc {
    font-size: 14px;
    line-height: 20px;
    color: #374151;

    a {
        border-bottom: solid 1px $primarycolor;
        transition: all 300ms ease-in-out;

        &:hover {
            border-color: transparent;
        }
    }

    *:last-child {
        margin-bottom: 0;
    }
}
