.footer {
    position: relative;
    padding: 145px 0 30px;
    color: white;
    background: black;
    overflow: hidden;

    @media screen and (max-width: 1200px) {
        padding: 50px 0 25px;
    }

    a {
        &:hover {
            color: lightgray;
        }
    }
}

.footer__block {
    font-family: 'Conv_GraphikLight';
    font-size: 14px;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            a {
                display: block;
                margin-bottom: 5px;
                padding-left: 18px;
                line-height: 28px;
                color: white;
                background-image: url(../../svg/chevron-right-opacity.svg);
                background-repeat: no-repeat;
                background-position: left top 6px;
                background-size: 6px 11px;

                &:hover {
                    background-image: url(../../svg/chevron-right.svg);
                    color: lightgray;
                }
            }
        }
    }

    &.footer__menu--materialen {
        ul {
            column-gap: 2rem;

            @media screen and (min-width: 992px) {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            @media screen and (min-width: 992px) {
                display: grid;
            }
        }
    }
}

.footer__block__title {
    margin-bottom: 15px;
    font-family: 'Conv_GraphikBold';
    font-size: 20px;
    display: flex;
    justify-content: space-between;

    a {
        color: white;

        &:hover {
            color: lightgray;
        }
    }
}

.footer__menu {
    &__toggle {
        background: transparent;
        border: none;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        width: 30px;
        height: 30px;
        transition: 0.3s ease-in-out transform;

        &.open {
            transform: rotate(180deg);
        }

        @media screen and (min-width: 992px) {
            display: none;
        }

        &:focus {
            outline: none;
        }

        svg {
            width: 17px;
            min-width: 17px;
            height: 17px;
        }
    }

    @media screen and (min-width: 992px) and (max-width: 1200px) {
        &:not(.footer__menu--materialen) {
            margin-top: 40px;
        }
    }

    @media screen and (max-width: 991px) {
        ul {
            height: 0;
            overflow: hidden;

            &.open {
                height: 100%;
            }
        }
    }
}

.footer__contact {
    @media screen and (max-width: 1200px) {
        margin-bottom: 26px;
    }
}

.footer__contact__link {
    display: block;
    margin-top: 26px;
    padding-left: 29px;
    line-height: 26px;
    color: white;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 14px 14px;

    &:last-child {
        margin-top: 0;
    }
}

.footer__contact__link--email {
    background-image: url(../../svg/envelope.svg);
}

.footer__contact__link--phone {
    background-image: url(../../svg/phone-alt.svg);
}

.footer__contact__btnlink {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    padding: 14px 23px;
    font-family: "Conv_GraphikMedium";
    font-size: 17px;
    text-transform: uppercase;
    color: white;
    background: $secondarycolor;

    &:hover,
    &:active,
    &:focus {
        color: white !important;
        background-color: lighten($secondarycolor, 5) !important;
    }

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        display: inline-block;
        width: 60px;
        height: 100%;
        content: "";
        background: rgba(black, 0.2) url(../../svg/arrow-right-white.svg) no-repeat center;
        background-size: 20px 20px;
    }
}

.footer__contact__btnlink--tertiary {
    background-color: #424242;

    &::after {
        background-color: rgba(white, 0.2);
    }

    &:hover,
    &:active,
    &:focus {
        color: white !important;
        background-color: lighten(#424242, 5) !important;
    }
}

.footer__bottom {
    display: flex;
    align-items: center;
    column-gap: 24px;
    justify-content: space-between;
    margin-top: 100px;
    font-size: 12px;
    line-height: 28px;
    position: relative;

    &::before {
        content: "";
        width: 100%;
        height: 1px;
        position: absolute;
        top: -28px;
        background: rgba(26, 26, 26, 0.21);
        background: linear-gradient(90deg, rgba(26, 26, 26, 0.21) 0%, rgba(26, 26, 26, 1) 50%, rgba(26, 26, 26, 0.21) 100%);
    }

    @media screen and (max-width: 1200px) {
        margin-top: 49px;
        flex-direction: column;
        row-gap: 10px;
    }
}

.footer__bottom__copy {
    color: #5c5c5c;

    a {
        color: #5c5c5c;

        &:hover {
            color: white;
        }
    }
}

.footer__bottom__links {
    display: flex;
    justify-content: center;

    ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            position: relative;

            &:last-child {
                a {
                    padding-right: 0;
                }
            }

            a {
                padding-right: 28px;
                color: #5c5c5c;

                &:hover {
                    color: white;
                }
            }

            &:not(:last-child)::after {
                position: absolute;
                right: 14px;
                display: inline-block;
                content: "|";
                color: #5c5c5c;
            }
        }
    }
}

.chat {
    position: fixed;
    z-index: 998;
    bottom: 16px;
    right: 0;
    display: flex;
    align-items: center;
    max-width: calc(100% - 52px);

    @media screen and (min-width: 1025px) {
        bottom: 26px;
        right: 26px;
    }

    @media (hover: hover) {
        &:hover {
            .chat__holder {
                transform: translateX(0);
                width: 416px;
            }
        }
    }

    @media (hover: none) {
        &.active {
            .chat__holder {
                transform: translateX(0);
                width: 416px;
            }
        }
    }

    &__image {
        border-radius: 100%;
        border: solid 1px #e7ebf4;
        margin-right: 16px;
        position: absolute;
        right: 0;
        z-index: 999;
        width: 82px;
        height: 82px;
        background-color: white;
        cursor: pointer;

        &__text {
            position: absolute;
            right: 45px;
            top: 15px;
            z-index: 50;
            width: 100px;

            @media screen and (max-width: 1024px) {
                right: 35px;
                top: 30px;
                width: 82px;
            }
        }

        @media screen and (min-width: 1025px) {
            width: 104px;
            height: 104px;
        }
    }

    &__holder {
        display: flex;
        background: white;
        z-index: 998;
        white-space: nowrap;
        width: 0;
        max-width: 100%;
        border-radius: 3px;
        padding: 20px 16px 20px 26px;
        box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
        justify-content: space-between;
        align-items: center;
        transform: translateX(416px);
        transition: 0.5s ease-in-out all;

        &__placeholder {
            display: block;
            width: 48px;
            height: 48px;

            @media screen and (min-width: 1025px) {
                width: 104px;
                height: 104px;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;

            &__title {
                font-size: 20px;
                line-height: 28px;
                font-family: Conv_GraphikBold;
                color: #000;
                margin-bottom: 3px;
            }

            &__subtitle {
                font-size: 14px;
                line-height: 20px;
                color: #000;
                margin-bottom: 5px;
            }

            &__link {
                display: flex;
                font-size: 14px;
                line-height: 28px;
                color: #000;
                transition: color 0.3s ease-in-out;

                &:hover {
                    color: $primarycolor;
                }

                svg {
                    color: $primarycolor;
                    width: 14px;
                    margin-right: 16px;
                }
            }
        }
    }
}

#smartsupp-widget-container > div,
#chat-application {
    visibility: hidden !important;
    opacity: 0 !important;
}

#smartsupp-widget-container > div[style*="height: calc(100%"],
#smartsupp-widget-container > div[style*="height: 100%"],
#chat-application[style*="height: calc(100vh"] {
    visibility: visible !important;
    opacity: 1 !important;
}
