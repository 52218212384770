.header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    padding: 32px 0;
    transition: all 0.3s;
    background-color: transparent;

    @media screen and (max-width: 1024px) {
        position: absolute;
        padding: 14px 0;
    }

    .container-fluid-full {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
    }

    .logo {
        position: relative;
        z-index: 1000;
        max-width: 246px;

        @media screen and (max-width: 1024px) {
            max-width: 158px;
        }

        svg {
            fill: white;
            width: 100%;
        }
    }

    .header__right {
        position: relative;
        z-index: 1000;
        display: flex;
        gap: 20px;

        .menu__toggler {
            display: none;
            border-radius: 3px;
        }

        a {
            display: flex;
        }

        @media screen and (max-width: 1024px) {
            .menu__toggler {
                display: flex;
            }

            a {
                display: none;
            }
        }
    }
}

.header__languages {
    position: relative;
    z-index: 55555;
    border: 0;
    margin: 0;
    padding: 0;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    min-width: 78px;
    overflow: hidden;
    border-radius: 0 0 3px 3px;

    .header__language__item {
        padding: 8px 10px;
        background: $primarycolor;
        color: $white;
        text-transform: uppercase;
        display: flex !important;
        column-gap: 10px;

        &:hover {
            background: lighten($primarycolor, 5);
        }
    }
}

//Header Languages
.header__language__button {
    width: max-content;
    height: 51px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    text-transform: uppercase;
    background: transparent;
    color: $white;
    display: flex;
    gap: 10px;
    font-size: 16px;
    line-height: 20px;
    padding: 12px 15px;
    transition: all 200ms ease-in-out;

    &:active,
    &:focus,
    &:hover {
        outline: 0;
        background: rgba(255, 255, 255, 0.2);
    }

    svg {
        margin-top: -2px;
        width: 10px;
        min-width: 10px;
        color: $primarycolor;
    }

    &__flag {
        display: flex;
        align-items: center;

        @media screen and (max-width: 1024px) {
            display: none;
        }
    }
}

.header__hover__background {
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 0.3s ease-in-out;

    &.active {
        opacity: 100;
    }
}

.header--fix:not(.menu--open) {
    .header {
        padding: 20px 0;
        background: white;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
    }

    .header__language__button {
        color: #000;
        border-color: #054b9630;
    }

    .main__nav__item__link {
        color: black;

        &:hover,
        &.active {
            color: $primarycolor;

            svg {
                color: $primarycolor;
            }
        }

        svg {
            color: black;
        }
    }

    .logo {
        display: block;

        svg {
            color: $primarycolor;
            fill: $primarycolor;
        }
    }
}

@media screen and (max-width: 1024px) {
    .header__language__button {
        height: 35px;
        width: 66px;
        font-size: 14px;
    }

    .header .header__right {
        gap: 10px;
    }
}
